import axios from "axios";
import {Message} from "element-ui";
import { Loading } from 'element-ui';
import router from "../router";
const baseURL='https://api.uplus100.fit/api'
// const baseURL='http://8.214.96.149:18001/api'
// const baseURL='http://192.168.0.101:9090/api'
export const baseUrl=baseURL
var loading=''
let isFirstMessage=1
 function request(config){
    const token = localStorage.getItem('token');
    let params={}
     if (!config.params){
         params.token = token
         config.params = params
     } else {
         config.params.token = token
     }
  const instance=axios.create({
    baseURL,
    // baseURL:'http://localhost:8081/api',
    // baseURL:'http://192.168.0.105:9090/api',
    // baseURL:'http://192.168.0.6:9090/api',
    // baseURL:'http://localhost:8080/api',
    // baseURL:'http://8.214.96.149:8899/api',
    timeout:60000,
    headers:{
      'Content-Type': 'text/plain',
      //   'Content-Type': 'application/x-www-form-urlencoded',
      // 'Authorization': localStorage.getItem('token')!=null? localStorage.getItem('token') : ''
    },
      withCredentials:true
      // SameSite=None

  })
  instance.interceptors.request.use(config=>{
      if (config.params.requestTimes == 10){

      } else{
          loading = Loading.service({
              lock: false,
              text: '加载中……',
              background: 'rgba(0, 0, 0, 0.7)'
          });
      }
      delete config.params.requestTimes
      setTimeout(()=>{loading.close()},10000)
    return config
  },err=>{
    console.log(err);
  })

  instance.interceptors.response.use(res=>{
      if (res.status==500) router.replace('/Server_error')
      if (res.status==404) router.replace('/Not_found')
      loading.close();
      if(res.data.toString().indexOf('not invalid token')!==-1) {
          if (isFirstMessage==1) {
              router.replace('/login')
              window.localStorage.removeItem('token')
              // window.localStorage.removeItem(JSON.parse(localStorage.getItem('userInfo')).id+'\'1-1-1')
              window.localStorage.removeItem('userInfo')
              Message.warning('登录身份已过期,请重新登录 !')
              isFirstMessage++
              return;
          }
        return
      }else {
          if (window.localStorage.getItem('token'))
          isFirstMessage = 1
      }
    return res.data
  },err=>{
    console.log(err);
    if (err.message.includes('timeout')){
      Message({
        message:'服务器繁忙',
        type:'warning'
      })
    }
  })

  return  instance(config)

}
export default request
